<template>
  <v-card>

    <v-dialog v-model="showAlertModal" max-width="450">
      <v-card>
        <v-card-text>
          <div id="icon">
          <v-icon @click="showAlertModal = false" color="primary" id="icon-child" size="45"  >
            {{ icons.mdiCloseCircleOutline }}
          </v-icon>
        </div>

          <v-container>
            <span class="text-h6">The form is ideally filled by Counsellor Cum Technician.
              <br />
              This form is <strong> Not filled</strong>
              yet.</span>
            <br /><br />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-card-title class="justify-center">
      <span class="text-h5"><strong>FORM C LABORATORY INFORMATION</strong></span>
    </v-card-title>
    <v-card-title class="justify-start mt-5">
      <span class="font-weight-semibold">BLOOD SAMPLE FOR SEROLOGY TEST</span>
    </v-card-title>
    <v-row class="mx-3">
      <v-col cols="12" md="4">
        <v-datetime-picker v-model="dateTimeOfCollection" date-format="dd-MM-yyyy" time-format="HH:mm" label="Date Time of Collection"
          :readonly="readOnlyField1 === true" :error="errr === false"></v-datetime-picker>
      </v-col>
      <v-col cols="12" md="8">
        <v-radio-group row class="mt-3" v-model="preValueList.mortem" :readonly="readOnlyField1 === true"
          :error="errr1 === false">
          <v-radio name="Parents_name" label="Pre" value="Pre"></v-radio>
          <v-radio name="Parents_name" label="Post" value="Post"></v-radio>
          <span class="mt-1 body-2">Mortem</span>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row class="mx-3">
      <v-col cols="12" md="2">
        <p class="mt-1">Collected By:*</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="preValueList.collected_by" hide-details class="mt-0 pt-0"
          :readonly="readOnlyField1 === true" :error="errr2 === false"></v-text-field>
      </v-col>
    </v-row>
    <!-- Laboratory infromation -->

    <!-- <v-card-title class="justify-center mb-10">
            <span class="text-h5"><strong>LABORATORY INFORMATION</strong></span>
          </v-card-title> -->
    <v-card-title class="justify-center mt-5">
      <span class="font-weight-semibold">LABORATORY INFORMATION</span>
    </v-card-title>

    <v-card-subtitle class="mb-0 pb-0 text-h6">
      <span class="font-weight-semibold text--primary me-1">WBC</span>
    </v-card-subtitle>
    <v-row class="mx-3 pt-3 mb-1">
      <v-col cols="12" md="4">
        <v-radio-group row hide-details class="mt-0" label="WBCs performed:*"
          v-model="preValueList.wbc_performed_yes_or_no"
          @change="resetFieldsData11(preValueList.wbc_performed_yes_or_no)" :readonly="readOnlyField1 === true"
          :error="errr3 === false">
          <v-radio name="Parents_name" label="Yes" value="Yes"></v-radio>
          <v-radio name="Parents_name" label="No" value="No"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row class="mx-3 mb-6 mt-0" v-if="preValueList.wbc_performed_yes_or_no == 'Yes'">
      <v-col cols="12" md="4" class="pb-0">
        <p class="body-2 my-0"><strong> Date and Time</strong></p>
      </v-col>
      <v-col cols="12" md="4" class="pb-0">
        <p class="body-2 my-0"><strong>Count</strong></p>
      </v-col>
      <v-col cols="12" md="4" class="pb-0"> </v-col>

      <template v-for="wbcObj in wbc_performed_arr_com">
        <v-col cols="12" md="4">
          <v-datetime-picker v-model="wbcObj.date_time" time-format="HH:mm" :text-field-props="textFieldProps"
            :readonly="readOnlyField1 === true">
          </v-datetime-picker>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field v-model="wbcObj.count" hide-details @keypress="onlyNumber1" :readonly="readOnlyField1 === true">
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4"> </v-col>
      </template>
    </v-row>

    <v-card-subtitle class="mb-0 pb-0 pt-5 text-h6">
      <span class="font-weight-semibold text--primary me-1">Temperature</span>
    </v-card-subtitle>
    <v-row class="mx-3 pt-3 mb-1">
      <v-col cols="12" md="5">
        <v-radio-group row hide-details class="mt-0" label="Temperature Recorder*"
          v-model="preValueList.temperature_recorded_yes_or_no" @change="
            resetFieldsData12(preValueList.temperature_recorded_yes_or_no)
          " :readonly="readOnlyField1 === true" :error="errr4 === false">
          <v-radio name="Parents_name" label="Yes" value="Yes"></v-radio>
          <v-radio name="Parents_name" label="No" value="No"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="5" md="4" v-if="preValueList.temperature_recorded_yes_or_no == 'No'">
        <v-text-field v-model="preValueList.temperature_recorded_no_reason" label="if No Reason" dense hide-details>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mx-3 mb-6 mt-0" v-if="preValueList.temperature_recorded_yes_or_no == 'Yes'">
      <v-col cols="12" md="4" class="pb-0">
        <p class="body-2 my-0"><strong>Date and Time</strong></p>
      </v-col>
      <v-col cols="12" md="4" class="pb-0">
        <p class="body-2 my-0"><strong>Result</strong></p>
      </v-col>
      <v-col cols="12" md="4" class="pb-0">
        <p class="body-2 my-0"><strong>Units</strong></p>
      </v-col>
      <template v-for="tempObj in temperature_arr_com">
        <v-col cols="12" md="4">
          <v-datetime-picker v-model="tempObj.date_time" time-format="HH:mm" :text-field-props="textFieldProps"
            :readonly="readOnlyField1 === true">
          </v-datetime-picker>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field v-model="tempObj.result" hide-details :readonly="readOnlyField1 === true"></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field v-model="tempObj.units" hide-details @keypress="onlyNumber" :readonly="readOnlyField1 === true">
          </v-text-field>
        </v-col>
      </template>
    </v-row>

    <v-card-subtitle class="mb-0 pb-0 pt-5 text-h6">
      <span class="font-weight-semibold text--primary me-1">Cultures</span>
    </v-card-subtitle>
    <v-row class="mx-3 pt-3 mb-1">
      <v-col cols="12" md="4">
        <v-radio-group hide-details row class="mt-0" label="Cultures Done*"
          v-model="preValueList.cultures_done_yes_or_no" @change="resetFieldsData(preValueList.cultures_done_yes_or_no)"
          :readonly="readOnlyField1 === true" :error="errr5 === false">
          <v-radio name="Parents_name" label="Yes" value="Yes"></v-radio>
          <v-radio name="Parents_name" label="No" value="No"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row class="mx-3 mb-8 mt-0" v-if="preValueList.cultures_done_yes_or_no == 'Yes'">
      <v-col cols="12" md="4" class="pb-0">
        <p class="body-2 mt-2 mb-0"><strong>Culture Source</strong></p>
      </v-col>
      <v-col cols="12" md="4" class="pb-0">
        <p class="body-2 mt-2 mb-0"><strong>Date and Time</strong></p>
      </v-col>
      <v-col cols="12" md="4" class="pb-0">
        <p class="body-2 mt-2 mb-0"><strong>Result</strong></p>
      </v-col>
      <template v-for="culObj in cultures_source_arr_com">
        <v-col cols="12" md="4">
          <v-text-field v-model="culObj.cultures_source" hide-details :readonly="readOnlyField1 === true">
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-datetime-picker v-model="culObj.date_time" time-format="HH:mm" :text-field-props="textFieldProps"
            :readonly="readOnlyField1 === true">
          </v-datetime-picker>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field v-model="culObj.result" hide-details :readonly="readOnlyField1 === true"></v-text-field>
        </v-col>
      </template>
    </v-row>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" v-if="saveBtn" @click="same()"> Save </v-btn>
    </v-card-actions>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<style>
 #icon{
  width:100%;
  text-align: right;    
 }

 #icon-child{
  margin-right: -10px;
  margin-top: 5px;
 }
</style>
<script>
import donorService from "@/service/Donor.service";
import { mdiMagnify, mdiCloseCircleOutline, mdiGithub } from '@mdi/js'
import moment from "moment";
const localData = JSON.parse(localStorage.getItem("token"));
const token = localData.token;
const userId = localData.user_id;
import { api } from "@/config/config";

export default {
  data() {
    return {
      errr: true,
      errr1: true,
      errr2: true,
      errr3: true,
      errr4: true,
      errr5: true,

      formtype: '',
      showAlertModal: false,
      icon: false,
      itemtype: "",
      textFieldProps: {
        // outlined: true,
        // dense: true,
        "hide-details": true,
      },
      Assisted: "",
      snackbar: false,
      snackbarText: "",
      timeout: 2000,
      e1: "1",
      od_check: false,
      readOnlyField: false,
      readOnlyField1: false,
      readOnlyField21: false,
      saveBtn: true,
      os_check: false,
      od_os_both: "",
      preValueList: {
        id: '',
        storage_medium: "",
        date_time_body_subjected_refrig: "",
        date_time_body_removed_refrig: "",
        recovery_date_time: "",
        date_time_of_collection: "",
        exp_date: "",
        evidence_of_surgery_os: "",
        temperature_recorded_yes_or_no: "",
        wbc_performed_yes_or_no: "",
        cultures_done_yes_or_no: "",
        assisted_by2: "",
        assisted_by2_os: "",
        assisted_by2_od: "",
        exp_date_calculate: "",
      },
      wbc_performed_arr: [
        { date_time: "", count: "" },
      ],
      temperature_arr: [
        { date_time: "", result: "", units: "" },
        { date_time: "", result: "", units: "" },
        { date_time: "", result: "", units: "" },
      ],
      cultures_source_arr: [
        { cultures_source: "", date_time: "", result: "" },
        { cultures_source: "", date_time: "", result: "" },
        { cultures_source: "", date_time: "", result: "" },
      ],
    };
  },

  setup() {


    return {
      // Icons
      icons: {
        mdiCloseCircleOutline,
      },
    }
  },

  computed: {

    dateTimeOfCollection: {
      get() {
        return moment.utc(this.preValueList.date_time_of_collection).format("DD-MM-YYYY HH:mm");
      },
      set(value) {
        this.$set(this.preValueList, "date_time_of_collection", moment(value).format("YYYY-MM-DD HH:mm")
        );
      },
    },

    // expDateTime: {
    //   get() {
    //     return moment(this.preValueList.exp_date_calculate).format(
    //       "YYYY-MM-DD HH:mm:ss"
    //     );
    //   },
    //   set(value) {
    //     this.$set(
    //       this.preValueList,
    //       "exp_date_calculate",
    //       moment(value).utcOffset("+05:30").format("YYYY-MM-DD HH:mm:ss")
    //     );
    //   },
    // },

    // PreservationDateTime: {
    //   get() {
    //     return moment(this.preValueList.Preservation_DateTime).format(
    //       "YYYY-MM-DD HH:mm:ss"
    //     );
    //   },
    //   set(value) {
    //     this.$set(
    //       this.preValueList,
    //       "Preservation_DateTime",
    //       moment(value).utcOffset("+05:30").format("YYYY-MM-DD HH:mm:ss")
    //     );
    //   },
    // },

    temperature_arr_com: function () {
      return this.temperature_arr.map((val) => {
        val.date_time = val.date_time
          ? moment(val.date_time).format("YYYY-MM-DD HH:mm")
          : "";
        return val;
      });
    },
    wbc_performed_arr_com: function () {
      return this.wbc_performed_arr.map((val) => {
        val.date_time = val.date_time
          ? moment(val.date_time).format("YYYY-MM-DD HH:mm")
          : "";
        return val;
      });
    },
    cultures_source_arr_com: function () {
      return this.cultures_source_arr.map((val) => {
        val.date_time = val.date_time
          ? moment(val.date_time).format("YYYY-MM-DD HH:mm")
          : "";
        return val;
      });
    },



    WBc_performed: function () {
      return this.Wbc_arr.map((val) => {
        val.date_time = val.date_time
          ? moment(val.date_time)
            .utcOffset("+05:30")
            .format("YYYY-MM-DD HH:mm")
          : "";
        return val;
      });
    },
    cultures_done: function () {
      return this.cultures_done_arr.map((val) => {
        val.date_time = val.date_time
          ? moment(val.date_time)
            .utcOffset("+05:30")
            .format("YYYY-MM-DD HH:mm")
          : "";
        return val;
      });
    },
    temperature_recorded: function () {
      return this.temperature_recorded_arr.map((val) => {
        val.date_time = val.date_time
          ? moment(val.date_time)
            .utcOffset("+05:30")
            .format("YYYY-MM-DD HH:mm")
          : "";
        return val;
      });
    },
    
  },

  watch: {
    options: {
      handler() {
        this.donorDeatilView();
        this.getdonorList();
      },
      deep: true,
    },
  },

  async mounted() {
    this.donorDeatilView();
    this.getdonorList();
  },

  methods: {
    same() {
      if (this.preValueList.date_time_of_collection == "" || this.preValueList.date_time_of_collection == null || this.preValueList.date_time_of_collection == 'Invalid date') {
        this.errr = false
        return false, (this.snackbarText = "Please fill the date time of collection"), (this.snackbar = true)
      } else if (
        this.preValueList.mortem == "" ||
        this.preValueList.mortem == null
      ) {
        this.errr1 = false
        return false,
          (this.snackbarText = "Please fill the Mortem"),
          (this.snackbar = true)

      } else if (
        this.preValueList.collected_by == "" ||
        this.preValueList.collected_by == null
      ) {
        this.errr2 = false
        return false,
          (this.snackbarText = "Please fill the Collected by"),
          (this.snackbar = true)

      } else if (this.preValueList.wbc_performed_yes_or_no == '' || this.preValueList.wbc_performed_yes_or_no == null) {
        this.errr3 = false
        return false,
          (this.snackbarText = "Please fill the WBCs performed"),
          (this.snackbar = true)

      }
      // if(this.preValueList.wbc_performed_yes_or_no == 'Yes'){
      //   if(this.wbc_performed_arr == '' || this.wbc_performed_arr == null){
      //     return false,
      //     (this.snackbarText = "Please fill the WBCs performed"),
      //     (this.snackbar = true)
      //   }
      // }

      else if (this.preValueList.temperature_recorded_yes_or_no == '' || this.preValueList.temperature_recorded_yes_or_no == null) {
        this.errr4 = false
        return false,
          (this.snackbarText = "Please fill the Temperature Recorder"),
          (this.snackbar = true)
      } else if (this.preValueList.cultures_done_yes_or_no == '' || this.preValueList.cultures_done_yes_or_no == null) {
        this.errr5 = false
        return false,
          (this.snackbarText = "Please fill the Cultures Done"),
          (this.snackbar = true)
      }
      else {
        this.errr = true
        this.errr1 = true
        this.errr2 = true
        this.errr3 = true
        this.errr4 = true
        this.errr5 = true
      }
      this.formCFive();
      

    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 3) {
        $event.preventDefault();
      }
    },
    onlyNumber1($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 4) {
        $event.preventDefault();
      }
    },

    resetFieldsData11(type) {
      if (type == "Yes") {
        this.wbc_performed_arr = [
          { date_time: "", count: "" },

        ];
      } else {
        this.wbc_performed_arr = [
          { date_time: "", count: "" },

        ];
      }
    },
    resetFieldsData12(type) {
      if (type == "Yes") {
        this.temperature_arr = [
          { date_time: "", result: "", units: "" },
          { date_time: "", result: "", units: "" },
          { date_time: "", result: "", units: "" },
        ];
        this.preValueList.temperature_recorded_no_reason = "";
      } else {
        this.temperature_arr = [
          { date_time: "", result: "", units: "" },
          { date_time: "", result: "", units: "" },
          { date_time: "", result: "", units: "" },
        ];
        this.preValueList.temperature_recorded_no_reason = "";
      }
    },

    resetFieldsData(type) {
      if (type == "Yes") {
        this.cultures_source_arr = [
          { cultures_source: "", date_time: "", result: "" },
          { cultures_source: "", date_time: "", result: "" },
          { cultures_source: "", date_time: "", result: "" },
        ];
      } else {
        this.cultures_source_arr = [
          { cultures_source: "", date_time: "", result: "" },
          { cultures_source: "", date_time: "", result: "" },
          { cultures_source: "", date_time: "", result: "" },
        ];
      }
    },

    async donorDeatilView() {
      const DonorId = this.$route.params.donor_id;
      const service = new donorService();
      let response = await service.FormCData(DonorId);
      this.preValueList = response;
      this.itemtype = response.storage_medium;
      this.readOnlyFun();
      if (this.preValueList.od_os_both == "1") {
        this.od_check = true;
      }
      if (this.preValueList.od_os_both == "2") {
        this.os_check = true;
      }
      if (this.preValueList.od_os_both == "3") {
        this.od_check = true;
        this.os_check = true;
      }
      if (this.preValueList.od_os_both == null) {
        this.od_check = false;
        this.os_check = false;
      }
      this.reandata(response);
      try {
        this.wbc_performed_arr = JSON.parse(this.preValueList.wbc_performed);
        this.cultures_source_arr = JSON.parse(this.preValueList.cultures_done);
        this.temperature_arr = JSON.parse(
          this.preValueList.temperature_recorded
        );
        // console.log("hello",this.temperature_arr)
      } catch (error) {
        console.log(error);
      }
    },
    reandata(response) {
      if (response.storage_medium == "Cornisol") {
        this.readOnlyField21 = true;
      } else {
        this.readOnlyField21 = false;
      }
    },

    async readOnlyFun() {
      if (this.preValueList.od_id == "" && this.preValueList.os_id == "") {
        this.readOnlyField = false;
      } else if (
        this.preValueList.od_id == null &&
        this.preValueList.os_id == null
      ) {
        this.readOnlyField = false;
      } else {
        this.readOnlyField = true;
      }
    },
    async getdonorList() {
      this.listLoading = true;
      const service = new donorService();
      let response = await service.donorList();
      if (response) {
        this.permissionData = response.permissions;
        var formCData = this.permissionData[3];
        this.formtype = this.permissionData[4]
        if (
          (formCData.view == "1" && formCData.edit == null) ||
          (formCData.view == "1" && formCData.edit == "0")
        ) {
          this.readOnlyField1 = true;
          this.saveBtn = false;

          if (!this.preValueList.id) {
            this.showAlertModal = true
          }
        }
      } else {
        this.totallist = 0;
      }
      this.listLoading = false;
    },

    async formCFive() {

      const data = {
        donor_id: this.$route.params.donor_id,
        user_id: userId,
        date_time_of_collection: moment(this.preValueList.date_time_of_collection).format('YYYY-MM-DD HH:mm'),
        mortem: this.preValueList.mortem,
        collected_by: this.preValueList.collected_by,
        wbc_performed: this.wbc_performed_arr,
        temperature_recorded: this.temperature_arr,
        temperature_recorded_no_reason:this.preValueList.temperature_recorded_no_reason,
        cultures_done: this.cultures_source_arr,
        wbc_performed_yes_or_no: this.preValueList.wbc_performed_yes_or_no,
        temperature_recorded_yes_or_no:this.preValueList.temperature_recorded_yes_or_no,
        cultures_done_yes_or_no: this.preValueList.cultures_done_yes_or_no,
      };
      try {
        const response = await api.post(`donor_medical/add`, data, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.status === true) {
          this.snackbarText = response.data.msg;
          this.snackbar = true;
          this.donorDeatilView();
          const item = this.$route.params.donor_id;
          if (this.formtype.view != '0' && this.formtype.view != null) {
            this.$router.push({ name: "donorss", params: { donor_id: item } });
          }
        } else {
          this.snackbarText = response.data.msg;
          this.snackbar = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
